import { render, staticRenderFns } from "./ReplyEditor.vue?vue&type=template&id=2b5390f2&scoped=true&"
import script from "./ReplyEditor.vue?vue&type=script&lang=js&"
export * from "./ReplyEditor.vue?vue&type=script&lang=js&"
import style0 from "./ReplyEditor.vue?vue&type=style&index=0&id=2b5390f2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b5390f2",
  null
  
)

export default component.exports